import React, { useCallback, useEffect, useState } from 'react';

import { Flex, HStack, Image, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { partial } from 'ramda';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';

import imagesApi, { ImageContentType } from 'api/images';
import usersApi from 'api/users';
import Avatar from 'components/primitives/Avatar';
import AppButton from 'components/primitives/Button';
import FormikPhoneTextField from 'components/primitives/FormikPhoneTextField';
import FormikTextField from 'components/primitives/FormikTextField';
import TextField from 'components/primitives/TextField';
import Typography from 'components/primitives/Typography';
import { ApiResponseError } from 'types/Api';
import { CountryData } from 'types/Country';
import { FilePreview } from 'types/File';

import { EditForm, UserInfoEditProps } from './UserInfoEdit.props';
import {
  transformUserDetailsToForm,
  toUpdatePayload,
} from './UserInfoEdit.utils';

const UserInfoEditView = (props: UserInfoEditProps): JSX.Element => {
  const toast = useToast();

  const [file, setFile] = useState<FilePreview>({
    preview: props.data.profilePic?.medium || '',
  });
  const [country, setCountry] = useState<CountryData>({
    countryCode: props.data.countryCode,
    dialCode: props.data.phoneCode,
    format: '',
    name: '',
  });

  const onChangePhoto = useCallback((acceptedFiles) => {
    setFile({
      file: acceptedFiles[0],
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onChangePhoto,
    accept: ['image/jpeg', 'image/png'],
    noKeyboard: true,
    noDrag: true,
  });

  const { mutate: update } = useMutation(
    partial(usersApi.doUpdate, [props.data.id]),
    {
      onSuccess: () => {
        toast({
          description: 'User updated successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onBack();
        props.refetch();
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const onSubmit = async (
    values: EditForm,
    otherProps: FormikHelpers<EditForm>
  ) => {
    try {
      otherProps.setSubmitting(true);
      let profilePic;
      if (file.file) {
        const profilePicData = await imagesApi.profile({
          id: props.data.id,
          contentType: file.file.type as ImageContentType,
        });
        await axios.put(profilePicData.url, file.file, {
          headers: {
            'Content-Type': file.file.type,
          },
        });
        profilePic = profilePicData.path;
      }
      await update(
        toUpdatePayload(
          values,
          country.dialCode,
          country.countryCode,
          profilePic
        )
      );
    } catch (e) {
      console.log(e);
    }
    otherProps.setSubmitting(false);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      file.file && URL.revokeObjectURL(file.preview);
    },
    [file]
  );

  return (
    <Formik<EditForm>
      initialValues={transformUserDetailsToForm(props.data)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Flex mt={3} flexDir="column" border="1px solid #ddd">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              px={4}
              py={4}
              borderBottom="1px solid #ddd"
            >
              <Typography variant="type8" color="shade5" weight="600">
                EDIT PROFILE
              </Typography>
            </Flex>
            <Flex flexDirection="column" p={5}>
              <Flex>
                <Avatar size={80} src={file.preview || ''} />

                <Flex
                  flexDir="column"
                  justifyContent="center"
                  ml={5}
                  {...getRootProps()}
                >
                  <AppButton outline variant="small" propagateEvent>
                    Change
                    <input {...getInputProps()} />
                  </AppButton>
                </Flex>
              </Flex>
              <Flex mt={8} maxW="300px" flexDir="column">
                <FormikTextField fieldName="firstName" label="First Name" />
                <FormikTextField fieldName="lastName" label="Last Name" />
                <TextField value={props.data.email} disabled label="Email" />
                <FormikPhoneTextField
                  prefix=""
                  onChangeCountry={(country) => setCountry(country)}
                  fieldName="phone"
                  label="Phone Number"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" mt={5}>
            <HStack spacing={5}>
              <AppButton type="submit" loading={isSubmitting} variant="small">
                Save
              </AppButton>
              <AppButton
                onClick={props.onBack}
                kind="secondary"
                variant="small"
              >
                Cancel
              </AppButton>
            </HStack>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default UserInfoEditView;
