import React from 'react';

import { CheckCircleIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Flex,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  chakra,
  SimpleGrid,
} from '@chakra-ui/react';

import StaticTable from 'components/layouts/StaticTable';
import Avatar from 'components/primitives/Avatar';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { useTheme } from 'utils/theme';

import { BuyListingGeneratedProps } from './BuyListing.props';
import {
  displayFields,
  matchesColumns,
  MODE,
  transactionHistoryColumns,
} from './BuyListing.utils';

const BuyListingView = (props: BuyListingGeneratedProps): JSX.Element => {
  const theme = useTheme();
  if (!props.data) return <Flex />;
  return (
    <Flex flexDirection="column">
      <HStack spacing={3} alignItems="flex-start">
        <Flex flexDir="column" border="1px solid #ddd" p={4} flex={4}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #ddd"
            m={-4}
            px={4}
            py={2}
            mb={4}
          >
            <Typography variant="type8" color="shade5" weight="600">
              BUYING PROFILE
            </Typography>
            <Flex>
              <Flex ml={2}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon color={theme.colors.primary} />}
                    border={`1px solid ${theme.colors.primary}`}
                    backgroundColor="#fff"
                  >
                    <Typography color="primary" weight="600">
                      Actions
                    </Typography>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => props.setMode(MODE.EDIT)}>
                      Edit
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          </Flex>
          <SimpleGrid columns={2}>
            {displayFields.map((field, i) => (
              <VStack
                key={`${field.field}${i}`}
                spacing={2}
                alignItems="flex-start"
                mt={5}
              >
                <Typography variant="type10" color="shade17">
                  {field.label}
                </Typography>
                <Typography
                  style={{ whiteSpace: 'pre-wrap' }}
                  variant="type8"
                  color="shade5"
                >
                  {field.getValue(props.data)}
                </Typography>
              </VStack>
            ))}
          </SimpleGrid>
        </Flex>
        <Flex flexDir="column" flex={3}>
          {props.data.user && (
            <Flex flexDir="column" border="1px solid #ddd" p={4}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #ddd"
                m={-4}
                px={4}
                py={2}
                mb={4}
              >
                <Typography variant="type8" color="shade5" weight="600">
                  SELLER DETAILS
                </Typography>
                <Button
                  onClick={props.onViewProfile}
                  border={`1px solid ${theme.colors.primary}`}
                  backgroundColor="#fff"
                >
                  <Typography color="primary" weight="600">
                    View Profile
                  </Typography>
                </Button>
              </Flex>
              <Flex>
                <Avatar
                  size={80}
                  src={props.data?.user.profilePic.medium || ''}
                />
                <VStack spacing={2} ml={5} alignItems="flex-start">
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data?.user.firstName} {props.data?.user.lastName}
                  </Typography>
                  <Typography variant="type9" color="shade5">
                    {props.data?.user.email}
                  </Typography>
                  <Typography variant="type9" color="shade5">
                    {props.data?.user.phone}
                  </Typography>
                </VStack>
              </Flex>
              <HStack mt={5} mx={-2} mb={-2} spacing={1}>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    SELLING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data?.user.userPropertiesCounts.selling}
                  </Typography>
                </VStack>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    BUYING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data?.user.userPropertiesCounts.buying}
                  </Typography>
                </VStack>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    FACILITATING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data?.user.userPropertiesCounts.facilitating}
                  </Typography>
                </VStack>
              </HStack>
            </Flex>
          )}
          <Flex mt={3} flexDir="column" border="1px solid #ddd">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              px={4}
              py={4}
            >
              <Typography variant="type8" color="shade5" weight="600">
                TRANSACTION HISTORY ({props.data.transactions.count})
              </Typography>
              <Flex></Flex>
            </Flex>
            <StaticTable
              columns={transactionHistoryColumns}
              data={props.transactionHistorySummary}
            />
            <Flex flexDir="column" m={4}>
              <AppButton
                onClick={() => props.setMode(MODE.TRANSACTIONS)}
                variant="small"
                outline
              >
                See All
              </AppButton>
            </Flex>
          </Flex>
          <Flex mt={3} flexDir="column" border="1px solid #ddd">
            <Flex flexDir="column" justifyContent="space-between" px={4} py={4}>
              <Typography variant="type8" color="shade5" weight="600">
                MATCHES (
                {(props.data?.matches.direct.count || 0) +
                  (props.data?.matches.matched.count || 0)}
                )
              </Typography>
            </Flex>
            <StaticTable
              columns={matchesColumns}
              data={props.matchesSummary || []}
            />
            <Flex flexDir="column" m={4}>
              <AppButton
                variant="small"
                outline
                onClick={() => props.setMode(MODE.MATCHES)}
              >
                See All
              </AppButton>
            </Flex>
          </Flex>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default BuyListingView;
